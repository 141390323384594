import "./src/styles/global.css"

import React, { useState } from 'react';
import { ClerkProvider } from '@clerk/clerk-react'
import { dark } from '@clerk/themes'

export const wrapRootElement = ({ element }) => {
    return <ClerkProvider
        appearance={dark}
        publishableKey={process.env.GATSBY_CLERK_PUBLISHABLE_KEY}
    >
        {element}
    </ClerkProvider>
}
